.formContainer {
  width: 100%;
  form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    h1 {
      margin: 0 0 8px 0;
      font-size: 18px;
      line-height: 48px;
      font-weight: 600;
    }
    .childBlock {
      width: 100%;
      padding-left: 24px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .questionBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;
      }
    }

    .rowContainer {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      row-gap: 16px;
      column-gap: 16px;
      width: 100%;
      align-items: center;
      .text {
        max-height: 6em;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        flex-basis: 300px;
        flex-grow: 1;
      }
      .questionInput {
        flex: 1 1 300px;
        > div {
          > textarea {
            border-radius: 0;
          }
        }
      }
      .buttonBlock {
        height: fit-content;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        gap: 24px;
        flex: 0 0 170px;
        height: fit-content;
        align-items: center;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    max-width: fit-content;
    margin: 32px 0 0 0;
    gap: 16px;
    .pageInfo {
      width: fit-content;
    }
    .footerButtons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
    }
  }

  @media screen and (min-width: 1024px) {
    // margin-top: 16px;

    form {
      max-height: 100%; //calc(100% - 82px); // navigation.height + navigation.margin
      padding: 20px 32px 24px 32px;
      margin: auto 0;
      overflow-y: auto;

      h1 {
        font-size: 32px;
      }
    }

    .navigation {
      width: 72vw;
      max-width: 1328px;
      padding-left: 36vw;
    }
  }

  @media screen and (min-width: 1920px) {
    form {
      h1 {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}
