$grayColor: #555;
$disabledColor: #757575;
$lightGrayColor: #efefef;
$navyColor: #143471;
$whiteColor: #fff;
$blackColor: #212121;

$iconButtonSize: 24px;

$mobileHeaderHeight: 76px;
$desktopHeaderHeight: 96px;

$headerMobileShadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);
$headerDesktopShadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
$popupDesktopShadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.11);

header {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: center;
  height: $mobileHeaderHeight;
  box-shadow: $headerMobileShadow;
  background: $whiteColor;

  .menuRed {
    color: #d32f2f !important;
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  .headerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 24px 24px 12px 24px;

    .logo {
      height: 100%;
      object-fit: contain;
    }

    .menuButton {
      display: flex;
      align-items: center;
      height: $iconButtonSize;
      width: $iconButtonSize;
      border: none;
      background: none;
      padding: 0;

      .menuSvg {
        height: 7px;
        width: 18px;
      }
    }
    .menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: fixed;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 100;
      height: 100vh;
      width: fit-content;
      min-width: 260px;
      right: 0;
      top: 0;
      padding: 32px 24px 0 36px;
      background: $whiteColor;
      box-shadow: 0px 4px 40px rgba($blackColor, 0.2);
      transform: translateX(100vw);
      transition: transform 150ms ease-in;

      &.opened {
        transform: translateX(0);
        opacity: 1;
        transition: 0.3s all;
      }

      .avatarContainer {
        display: flex;
        align-items: center;
        padding: 0 2.5vw 12px 0;
        border-bottom: 1px solid $lightGrayColor;

        .userData {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: auto;
          max-width: calc(100% - 80px);

          .userName,
          .userPost {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .userName {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $blackColor;
          }
          .userPost {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: $disabledColor;
          }
        }
        .arrow {
          width: 14px;
          height: 8px;
          margin-left: 6px;
          transform: rotate(-90deg);
          order: 0;
          transition: 0.5s all;
          cursor: pointer;

          path {
            fill: $disabledColor;
          }

          &.back {
            transform: rotate(90deg);
            order: -1;
            transition: 0.5s all;
            margin-left: 0;
            margin-right: 6px;
          }
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        background: $whiteColor;

        li {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.active > a {
            color: $navyColor;
          }
        }
      }

      .menuList {
        margin-top: 20px;
        background: none;
        order: 0;
        transform: translateX(0);
        transition: 0.3s all;

        .menuItem {
          margin-bottom: 12px;

          & > a {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
        }

        &.hidden {
          order: 1;
          transform: translateX(-150vw);
          transition: 0.3s all;
        }
      }

      .mobileMenu {
        .userMenu {
          background: none;
          transform: translateX(150vw);
          opacity: 0;
          transition: 0.3s all;

          li {
            margin-top: 12px;

            a {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
            }
          }

          &.opened {
            transform: translateX(0);
            opacity: 1;
            transition: 0.3s all;
          }
        }
      }
    }

    .menuBlur {
      position: fixed;
      z-index: 99;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      background: $whiteColor;
      backdrop-filter: blur(4px);
      opacity: 0;
      pointer-events: none;
      transition: opacity 50ms ease-in;

      &.opened {
        opacity: 0.8;
        pointer-events: all;
        transition: opacity 50ms ease-out 100ms;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .headerContent {
      padding: 0 48px;

      .menuButton,
      .menuBlur {
        display: none;
      }

      .wideMenu {
        position: relative;
        display: flex;
        margin: 0;

        .avatarContainer {
          position: relative;
          display: flex;

          .avatar {
            position: relative;
            display: flex;
            z-index: 0;
          }
          .userMenuBtn {
            position: absolute;
            z-index: 1;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            margin-left: 60%;
            margin-top: 60%;
            background-color: $whiteColor;
            cursor: pointer;

            &::after {
              position: relative;
              content: "";
              width: 0;
              height: 0;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-top: 4px solid $blackColor;
              top: 50%;
              left: calc(50% - 4px);
              transform: translate(-50%, -50%);
            }
          }
        }
        .userMenu {
          display: none;
          position: absolute;
          z-index: 100;
          width: auto;
          transform: translateX(-80%);
          top: 120%;
          left: 0;
          padding: 12px 40px 24px 40px;
          margin: 0;
          border-radius: 8px;
          background: $whiteColor;
          box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.11);
          list-style: none;

          &.opened {
            display: block;
          }

          li {
            margin-top: 12px;

            a {
              cursor: pointer;
              white-space: nowrap;
              font-size: 16px;
              line-height: 24px;
              font-weight: 600;
            }
          }
        }
      }

      .menu {
        position: relative;
        z-index: 100;
        overflow-y: unset;
        overflow-x: unset;
        height: unset;
        max-width: 100%;
        padding: 0 20px;
        right: unset;
        top: unset;
        padding: 0;
        box-shadow: none;
        transform: none;
        background: none;
        width: 100%;

        .menuList {
          display: flex;
          justify-content: space-around;
          margin-top: unset;

          .menuItem {
            margin-bottom: 0;

            & > a {
              font-size: 16px;
            }
          }
          .userMenu {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    height: $desktopHeaderHeight;
    box-shadow: $headerDesktopShadow;
    background: rgba($whiteColor, 0.8);
    backdrop-filter: blur(16px);

    .headerContent {
      padding: 0 5vw;

      .menu {
        background: none;
      }

      .logo {
        width: 200px;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    .headerContent {
      max-width: 1920px;

      .logo {
        width: 300px;
      }

      .menu {
        max-width: 100%;
      }

      .avatarMenu {
        .userMenu {
          transform: translateX(-50%);
        }
      }
    }
  }

  @media screen and (min-width: 1920px) {
    .headerContent {
      padding: 0 12vw;
    }
  }
}
