$whiteColor: #fff;

.modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 78px; // 24 (margin-top) + 20 (margin-bottom) + 34 (button height)
  padding-bottom: 30px;
  overflow: hidden;
  background-color: $whiteColor;

  .closeBtn {
    position: fixed;
    top: 24px;
    right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    min-width: 34px;
    padding: 10px;
    border: none;
    background: none;

    .closeSvg {
      width: 14px;
      height: 14px;
    }
  }

  .content {
    .chartArea {
      width: calc(100vw - 32px);
      height: 35vh;
      max-width: 500px;
      max-height: 500px;
    }

    .accordionArea {
      width: calc(100vw - 48px);
      max-width: 540px;
      height: calc(65vh - 120px);
      margin-top: 10px;
      overflow-y: auto;

      :global(.MuiAccordion-root > .MuiAccordionSummary-root) {
        font-size: 20px;
        line-height: 32px;
      }
      :global(.MuiAccordion-root > .MuiCollapse-root) {
        padding-left: 8px;
      }
      :global(.MuiAccordion-root > .MuiCollapse-root .MuiCollapse-root) {
        padding-left: 0;
      }
      :global(
          .MuiAccordion-root > .MuiCollapse-root .MuiAccordionSummary-root
        ) {
        font-size: 16px;
      }
      :global(.MuiAccordionDetails-root) {
        max-height: unset;
      }
      :global(.MuiAccordionSummary-expandIconWrapper) {
        display: none;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 78px 40px 30px 40px;

    .content {
      flex-wrap: nowrap;

      .chartArea {
        width: 45vw;
        height: 45vw;
        max-width: 500px;
        max-height: 80vh;
        margin-right: 8vw;
      }

      .accordionArea {
        width: 47vw;
        max-width: 600px;
        height: unset;
        max-height: 75vh;
        overflow-y: auto;
        padding-right: 20px;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    .content {
      .chartArea {
        max-width: 600px;
      }
    }
  }

  @media screen and (min-width: 1536px) {
    .content {
      .chartArea {
        max-width: 700px;
      }
    }
  }
}
