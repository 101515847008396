$mobileCircleSize: 200vw;
$mobileSvgSize: 140vw;
$desktopCircleSize: max(88vw, 173vh);
$desktopSvgSize: max(46vw, 91vh);
$desktopAccordionAreaWidth: max(27vw, 47vh);
$desktopInfoAreaWidth: max(73vw, 53vh);

.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 101vw;
  left: 0;
  top: 0;
  padding-top: 76px;
  z-index: 0;

  .lazyImage {
    background-position: left center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    position: fixed;
    display: block;
    height: 100vh;
    min-height: 1px;
    width: 100vw;
    min-width: 1px;
    top: 0;
    z-index: 0;
    transform: translateY(0);
    transition: 1s transform;

    &.active {
      transform: translateY(0);
      transition: 1s transform;
    }
    &.previous {
      transform: translateY(-100vh);
      transition: 1s transform;
    }
    &.next {
      transform: translateY(100vh);
      transition: 1s transform;
    }
  }

  .infoArea,
  .accordionArea {
    position: relative;
    display: block;
  }
  .accordionArea {
    width: calc(100% - 48px);
    margin: 12px 24px 0 24px;
    max-height: 0px;
    opacity: 0;

    .accordionGroup {
      :global(.MuiAccordion-root) {
        padding: 0;

        :global(.MuiAccordionSummary-root) {
          width: fit-content;

          :global(.MuiAccordionSummary-content) {
            margin-right: 13px;
            font-size: 20px;
            line-height: 32px;
            color: white;
          }

          :global(.MuiAccordionSummary-expandIconWrapper) > svg > path {
            fill: white;
          }
        }
        :global(.MuiAccordionDetails-root) {
          max-height: unset;
          padding: 0;

          ul {
            padding: 0;
            margin: 0;
            list-style: none;

            a {
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              cursor: pointer;
              color: white;

              &:global(.active) {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
  .infoArea {
    height: fit-content;
    width: 100%;

    .aboutSvg {
      display: none;
    }

    .info {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: fit-content;
      min-height: $mobileSvgSize;
      left: 40%;
      transform: translateX(-40%);
      padding: 0 7.5vw 32px 7.5vw;
      overflow: hidden;

      .circle {
        position: absolute;
        border-radius: 50% 50% 0 0;
        width: $mobileCircleSize;
        height: $mobileCircleSize;
        background-color: rgba(255, 255, 255, 0);
        margin-left: -57.5%;
        z-index: 0;

        .aboutSvg {
          position: absolute;
          display: block;
          z-index: 0;
          width: $mobileSvgSize;
          height: $mobileSvgSize;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &::after {
        position: absolute;
        top: $mobileCircleSize;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
        margin-left: -7.5vw;
        z-index: -1;
      }

      h1 {
        margin-top: 10vw;
        font-size: 20px;
        line-height: 32px;
      }
      p {
        margin: 0;
        margin-bottom: 0.5em;
      }
      :global(.MuiAccordion-root) {
        padding: 8px 0 0 0;
        :global(.MuiAccordionSummary-root) {
          width: fit-content;
          :global(.MuiAccordionSummary-content) {
            margin-right: 13px;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #18397a;
          }
        }
        :global(.MuiAccordionDetails-root) {
          max-height: unset;
          padding: 0;
        }
      }
    }

    :global(.play) {
      cursor: pointer;
      circle {
        filter: drop-shadow(0px 4px 6px #ccc);
      }
    }
    :global(.chart) {
      opacity: 0.6;
      transform: translate(50%, 50%);
    }
    :global(.smallCircle) {
      opacity: 1;
    }
    :global(.innerSmallCircle) {
      fill: rgba(255, 255, 255, 0);
      transition: 1s fill;
    }
  }

  &.playing {
    .accordionArea {
      margin: 12px 24px 25px 24px;
      max-height: 200%;
      transition:
        1s margin,
        1s max-height;
    }
    .infoArea {
      .info {
        min-height: calc(100vh - 76px - 32px - 25px - 12px);
        transition: 1s min-height;
        pointer-events: all;

        .circle {
          margin-left: -62%;
          z-index: -1;
          transition: 1s margin-left;
          pointer-events: none;

          .aboutSvg {
            opacity: 1;
            transform: translate(-50%, 21%) scale(2.05);
            transition: 1s transform;

            :global(.chart) {
              transform: scale(1.2) translate(41.75%, 41.75%);
              transition: 1s transform;
            }
          }
        }
      }

      :global(.largeCircle),
      :global(.middleCircle),
      :global(.about) {
        opacity: 0;
        transition: 0.2s opacity;
      }
      :global(.innerSmallCircle) {
        opacity: 1;
        fill: rgba(255, 255, 255, 0.6);
        transition: 1s fill;
      }
      :global(.smallCircle) {
        opacity: 0.5;
        transition: 1s opacity;
      }
      :global(.chart) {
        opacity: 0.3;
        transition: 1s opacity;
      }
    }
  }

  &.played {
    .accordionArea {
      opacity: 1;
      margin: 12px 24px 25px 24px;
      max-height: unset;
      transition: 1s all;
    }
    .infoArea {
      .info {
        min-height: calc(100vh - 76px - 32px - 25px - 12px);
        pointer-events: all;

        .circle {
          margin-left: -62%;
          z-index: -2;
          background-color: rgba(255, 255, 255, 0.8);
          transition: 1s background-color;
          pointer-events: none;

          .aboutSvg {
            opacity: 0;
            transform: translate(-50%, 21%) scale(2.05);
            transition: 2s opacity;

            :global(.chart) {
              transform: scale(1.2) translate(41.75%, 41.75%) rotate(45deg);
              transition: 3s transform;
            }
          }
        }
      }

      :global(.largeCircle),
      :global(.middleCircle),
      :global(.about) {
        opacity: 0;
      }
      :global(.chart) {
        opacity: 0.3;
        transition: 3s transform;
      }
      :global(.innerSmallCircle) {
        fill: rgba(255, 255, 255, 0.6);
      }
      :global(.smallCircle) {
        opacity: 0.5;
      }
      :global(.innerSmallCircle) {
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: 1024px) and (orientation: landscape) {
    padding-top: 76px;
    overflow: hidden;
    flex-direction: row;

    .accordionArea {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      width: calc((100vw - $desktopSvgSize) / 2);
      padding-right: 26px;
      margin: 0;
      opacity: 0;

      .accordionGroup {
        width: 85%;
        max-width: 300px;

        :global(.MuiAccordion-root) {
          :global(.MuiAccordionSummary-root) {
            width: fit-content;

            :global(.MuiAccordionSummary-content) {
              font-size: 24px;
              line-height: 36px;
            }
          }
          :global(.MuiAccordionDetails-root) {
            ul {
              li {
                margin-bottom: 20px;

                a {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 20px;
                  &:global(.active) {
                    font-size: 24px;
                    line-height: 29px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .infoArea {
      height: 100%;
      width: calc((100vw - $desktopSvgSize) / 2 + $desktopSvgSize);
      overflow: hidden;

      .circle {
        position: absolute;
        border-radius: 50%;
        width: $desktopCircleSize;
        height: $desktopCircleSize;
        background-color: rgba(255, 255, 255, 0);
        margin-top: calc($desktopCircleSize * -0.2);
        z-index: 0;

        .aboutSvg {
          position: absolute;
          display: block;
          top: 50%;
          transform: translateY(-58%);
          height: $desktopSvgSize;
          width: $desktopSvgSize;
          z-index: 0;
        }
      }

      .info {
        position: absolute;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        min-height: unset;
        max-height: 100%;
        padding: 20% 20% 20% 15%;
        top: 0;
        bottom: 0;
        margin: auto 0;
        pointer-events: none;

        .circle,
        &::after,
        .aboutSvg {
          display: none;
        }

        h1 {
          margin-top: unset;
          font-size: 32px;
          line-height: 48px;
        }

        :global(.MuiAccordion-root) {
          :global(.MuiAccordionSummary-root) {
            :global(.MuiAccordionSummary-content) {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }

    &.playing {
      .accordionArea {
        width: $desktopAccordionAreaWidth;
        margin: 0;
        transition: 1s width;
      }
      .infoArea {
        width: $desktopInfoAreaWidth;
        transition: 1s width;

        .circle {
          pointer-events: none;

          .aboutSvg {
            opacity: 1;
            transform: translate(45%, -50%) scale(2.74);
            transition: 1s transform;

            :global(.chart) {
              transform: scale(1.2) translate(41.75%, 41.75%);
              transition: 1s transform;
            }
          }
        }
      }
    }
    &.played {
      .accordionArea {
        width: $desktopAccordionAreaWidth;
        margin: 0;
      }
      .infoArea {
        width: $desktopInfoAreaWidth;

        .circle {
          background-color: rgba(255, 255, 255, 0.8);
          transition: 1s background-color;
          pointer-events: none;

          .aboutSvg {
            transform: translate(45%, -50%) scale(2.74);
            opacity: 0;
            transition: 2s opacity;

            :global(.chart) {
              transform: scale(1.2) translate(41.75%, 41.75%) rotate(45deg);
              transition: 3s transform;
            }
          }
        }

        .info {
          min-height: unset;
          clip-path: polygon(
            0% 20%,
            80% 20%,
            80% 80%,
            90% 80%,
            90% 0%,
            100% 0%,
            100% 100%,
            90% 100%,
            90% 80%,
            0% 80%
          );
        }
      }
    }
  }
}
