$navyColor: #18397a;

form {
  h1 {
    font-size: 20px;
    line-height: 24px;
    margin-top: -12px;
    color: $navyColor;
  }

  .disclaimerBtn {
    width: 100%;
    justify-content: flex-start;
    text-transform: unset;
    margin-bottom: 8px;
  }
  .input {
    margin-bottom: 8px;
    width: 100%;
  }

  .docBtn {
    margin-top: 12px;
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    justify-content: space-between;

    h1 {
      font-size: 22px;
      line-height: 26px;
    }

    .disclaimerBtn {
      margin-bottom: 16px;
    }

    .input {
      margin-bottom: 20px;
      width: calc(50% - 8px);
    }

    .docBtn {
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      width: auto;
      margin-top: 4px;
    }
  }

  @media screen and (min-width: 1440px) {
    h1 {
      font-size: 24px;
      line-height: 28px;
      margin-top: -12px;
    }
    .inputBlock {
      .wide {
        width: calc(39% - 11px);
      }
      .normal {
        width: calc(29% - 11px);
      }
      .docBtn {
        margin: 0;
        margin-bottom: 20px;
      }
    }
  }

  @media screen and (min-width: 1920px) {
    h1 {
      font-size: 28px;
      line-height: 32px;
      margin-top: -14px;
    }
  }
}

.dayPicker {
  &.draftExist {
    background-color: red;
  }
}
